

// 计时器工具类
class timer {
    timerList = [];
    createTimer(name, callback, time = 1000) {
        this.timerList.push({
            name,
            callback,
            time
        });
        this.runTimer(name);
    };

    runTimer(name) {
        const _that = this;
        (function inner(){
            const task = _that.timerList.find(item => {
                return item.name === name;
            });
            if (!task) return;
            task.t = setTimeout(() => {
                task.callback();
                clearTimeout(task.t);
                inner();
            }, task.time);
        })()
    };

    clearTimer(name) {
        const taskIndex = this.timerList.findIndex(item => item.name === name);
        if (taskIndex !== -1) {
            clearTimeout(this.timerList[taskIndex].t);
            this.timerList.splice(taskIndex, 1);
        }
    }
};

export default new timer();