<template>
  <div class="mg-dropdown-button">
    <el-button v-bind="$attrs" @click="handleClick" v-if="!showIcon">
      <slot />
    </el-button>
    <el-dropdown v-on="$listeners" :disabled="$attrs['disabled']" v-else @after-command="afterCommand">
      <el-button v-bind="$attrs" @click="handleImmediateClick">
        <slot />
        <i class="el-icon-arrow-down el-icon--right" />
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="item in data" v-bind="item" :key="item.command" :command="item">
          <template v-if="!item.children">
            {{ item.label }}
          </template>
          <template v-else>
            <el-dropdown v-on="$listeners">
              <span>
                {{ item.label }}
                <i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="sonItem in item.children"
                  v-bind="sonItem"
                  :key="sonItem.command"
                  :command="sonItem"
                >
                  {{ sonItem.label }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  import { DropdownButton } from '@mg/mg-ui'
  export default {
    name: DropdownButton.name,
    extends: DropdownButton,
  }
</script>
