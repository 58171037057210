import timer from './timer'

/**
 * 延迟执行
 * @param {*} duration
 * @returns Promise
 */
export function delay(duration = 4) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}
/**
 * 下载文件
 * @param {Blob} blob
 */
export function downloadFile(blob) {
  const a = document.createElement('a')
  a.download = decodeURI(blob.headers['content-disposition'].split("''")[1])
  a.style.display = 'none'
  a.href = URL.createObjectURL(blob.data)
  document.body.appendChild(a)
  a.click()
  URL.revokeObjectURL(a.href)
  document.body.removeChild(a)
}
/**
 * 创建计时器函数
 * @param { String } name 计时器名称
 * @param { Function } func 计时器执行函数
 * @param { Number } time 计时器间隔
 */
export function actionTimer(name, func, time) {
  timer.clearTimer(name)
  timer.createTimer(
    name,
    () => {
      func()
    },
    time
  )
}

/**
 * m 到 n 的随机数
 * @param {*} m
 * @param {*} n
 * @returns
 */
export function random(m, n) {
  return Math.floor(Math.random() * (m - n) + n)
}
