const install = (Vue) => {
  const files = require.context('.', false, /\.js$/)
  const directives = {}

  files.keys().forEach((key) => {
    const fileName = key.replace(/(\.\/|\.js)/g, '')
    if (fileName !== 'index') {
      directives[fileName] = files(key).default
    }
  })

  Object.keys(directives).forEach((key) => {
    Vue.directive(key, directives[key])
  })
}

if (typeof window !== void 0 && window.Vue) {
  install(window.Vue)
}

export default install
