import { getGzipConfig } from '@/api/config'
import { LocaleStorage } from '@/utils/storage'
import { querySysBusinessConfig } from '@/api/set/systemBusinessSet'
const state = () => ({
  // 系统业务配置
  sysBusinessConfig: {
    // 是否允许负库存
    ifVirtualStock: void 0,
    // 负库存是否提示
    ifNegativeInventory: void 0,
    // 是否零价格销售
    ifZeroPrice: void 0,
    // 现款使用预收
    ifDepositReceived: false,
    // 是否记录结案原因
    ifRecordClosedReason: void 0,
    // 是否启用促销
    ifEnablePromotion: void 0,
    // 第三方支付弹窗
    ifThirdPayPopup: void 0,
  },

  // 接口压缩配置
  gzipConfig: null,

  // 中文:zh-CN,英文:en-US,印尼 id
  lang: LocaleStorage.get(),
})
const getters = {
  gzipConfig: (state) => state.gzipConfig,
  lang: (state) => state.lang,
  sysBusinessConfig: (state) => state.sysBusinessConfig,
}
const mutations = {
  setGzipConfig(state, gzipConfig) {
    state.gzipConfig = gzipConfig
  },
  setLang(state, lang) {
    state.lang = lang
    LocaleStorage.set(lang)
  },
  setSysBusinessConfig(state, data) {
    state.sysBusinessConfig = { ...state.sysBusinessConfig, ...data }
  },
}
const actions = {
  async getZipConfig({ commit }) {
    try {
      const { data: systemConfig } = await getGzipConfig()
      commit('setGzipConfig', systemConfig.gzipConfig)
    } catch (e) {
      console.error('系统配置接口‘/system/systemConfig’错误')
    }
  },
  async setLang({ commit }, lang) {
    commit('setLang', lang)
  },

  async getSysBusinessConfig({ commit, state }, payload = '') {
    if (Object.values(state.sysBusinessConfig).some((i) => i === void 0)) {
      const { data } = await querySysBusinessConfig(payload.params ?? '')
      commit('setSysBusinessConfig', data[0] ?? {})
    }
    return state.sysBusinessConfig
  },

  setSysBusinessConfig({ commit }, data) {
    commit('setSysBusinessConfig', data ?? {})
  },
}
export default { state, getters, mutations, actions }
