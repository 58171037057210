<template>
  <el-container class="mg-report-container">
    <el-aside :width="width" class="report-menus">
      <div class="collapse-title">
        <i class="iconfont" :class="iconClass" @click="handleCollapse" />
      </div>
      <div class="report-menus-items">
        <div
          v-for="item in buttons"
          :key="item.menuId"
          class="menu-item"
          @click="handleToLink(item)"
          :class="{ active: activeMenuId === item.menuId }"
          :title="item.menuName"
        >
          {{ item.menuName }}
        </div>
      </div>
    </el-aside>
    <el-main class="report-content">
      <iframe
        class="third-page"
        frameborder="0"
        v-for="item in iframes"
        :key="item.menuId"
        v-show="activeMenuId === item.menuId"
        :src="item.extLink"
      />
    </el-main>
  </el-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import md5 from 'crypto-js/md5'
  import qs from 'qs'
  import { salt, channel } from '@/config'
  import AES from '@/utils/cryptojsAES'
  export default {
    data() {
      return {
        visible: true,
        activeMenuId: 0,
        iframes: [],
      }
    },
    computed: {
      ...mapGetters({
        belongId: 'user/belongId',
        belongCode: 'user/belongCode',
        employeeName: 'user/employeeName',
      }),
      buttons() {
        return this.$route.meta.buttons || []
      },
      iconClass() {
        return this.visible ? 'mg-icon_shangyiye' : 'mg-icon_xiayiye'
      },
      width() {
        return this.visible ? '224px' : '40px'
      },
      isActiveIframe() {
        return this.iframes.findIndex((item) => item.menuId === this.activeMenuId) !== -1
      },
    },
    mounted() {
      this.setActiveMenuItem()
    },
    methods: {
      setActiveMenuItem() {
        const button = this.buttons.find((item) => item.menuId == this.$route.query.menuId)
        if (button) {
          this.handleToLink(button)
        }
      },
      handleToLink(item) {
        this.activeMenuId = item.menuId
        // 需要AES加密的链接处理
        const needAESUrls = ['dh.datart.mg.cn']
        // 十六位十六进制数作为密钥
        const key = 'RktYdXd47yPjvq2c'
        const AESEncryptText = AES.encrypt(key, { param_partner_code: this.belongCode })
        this.$nextTick(() => {
          if (!this.isActiveIframe) {
            if (item.extLink.startsWith('http')) {
              const url = new URL(item.extLink)
              const params = qs.parse(url.search, { ignoreQueryPrefix: true })
              if (needAESUrls.includes(url.host)) {
                // 链接有效性: 1个小时
                const AESXtimestamp = new Date().getTime() + 60 * 60 * 1000
                // 验签 -- sourceCode + timestamp + secre 进行md5加密
                const signature = md5('2' + AESXtimestamp + 'b9bf7aa941d043017ec667287d6c2d64').toString()
                params['user'] = this.employeeName
                params['parameter'] = AESEncryptText
                params['timestamp'] = AESXtimestamp
                params['signature'] = signature
                const urlParams = Object.keys(params).map((item) => {
                  let itemStr = `${item}=${params[item]}`
                  return itemStr
                })
                item.extLink = `${url.origin}${url.pathname}?&${urlParams.join('&')}`
                this.iframes.push(item)
                return false
              }
              params.param_partner_id = this.belongId
              params.param_partner_code = this.belongCode
              url.search = qs.stringify(params, { addQueryPrefix: true })
              const xtimestamp = new Date().getTime()
              const xsign = md5(md5(url.pathname + url.search + salt).toString() + xtimestamp).toString()
              params['X-SIGN'] = xsign
              params['X-EXT-CHANNEL'] = channel
              params['X-TIMESTAMP'] = xtimestamp
              url.search = qs.stringify(params, { addQueryPrefix: true })
              item.extLink = url.toString()
              this.iframes.push(item)
            }
          }
        })
      },
      handleCollapse() {
        this.visible = !this.visible
      },
    },
  }
</script>
<style lang="scss">
  .mg-report-container {
    min-height: calc(100vh - 100px) !important;
    background: #ebedf0 !important;
    padding: 0 !important;

    .report-menus {
      background: #fff;
      margin-right: 8px;
      .collapse-title {
        text-align: right;
        border-bottom: 1px solid #ddd;
        padding: 3px 8px;
        i {
          font-size: 18px;
          border: 1px solid #ddd;
          border-radius: 4px;
          &:hover {
            color: #e60012;
            border-color: #e60012;
          }
        }
        span {
          color: #252a3d;
          font-weight: 500;
        }
      }
      .report-menus-items {
        padding: 10px 0px;
        height: calc(100vh - 150px);
        overflow: auto;
        .menu-item {
          line-height: 17px;
          color: #292e40;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 30px;
          padding: 0 12px;
          &:hover {
            background: rgba(37, 42, 61, 0.05);
            cursor: pointer;
          }
          &.active {
            color: '#e60012';
            background: rgba(37, 42, 61, 0.05);
          }
        }
      }
    }

    .report-content {
      padding: 8px;
      background: #fff;
      .third-page {
        width: 100%;
        height: calc(100% - 8px);
      }
    }
  }
</style>
