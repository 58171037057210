export function setStorage(key, data) {
  if (data !== null && typeof data === 'object') {
    return localStorage.setItem(key, JSON.stringify(data))
  }
  localStorage.setItem(key, data)
}

export function getStorage(key, defaultValue) {
  const result = localStorage.getItem(key)
  if ((result?.startsWith('{') && result?.endsWith('}')) || (result?.startsWith('[') && result?.endsWith(']'))) {
    return JSON.parse(result)
  }
  if (result === 'true') return true
  if (result === 'false') return false
  return result === 'null' || result === null ? defaultValue : result
}

export function removeStorage(key) {
  localStorage.removeItem(key)
}

const BaseStorage = {
  set(value = '', key) {
    setStorage(key || this.key, value)
  },
  get(key, defaultValue) {
    return getStorage(key || this.key, defaultValue)
  },
  clear(key) {
    removeStorage(key || this.key)
  },
}

// 缓存 TabsBar
export const TabsBarStorage = Object.assign(Object.create(BaseStorage), {
  key: 'MG_TABS_BAR',
})

// 缓存用户信息
export const UserStorage = Object.assign(Object.create(BaseStorage), {
  key: 'MG_USER_INFO',
})

// 是否需要修改密码
export const ChangePwdStorage = Object.assign(Object.create(BaseStorage), {
  key: 'MG_IS_CHANGE_PWD',
})

// token
export const TokenStorage = Object.assign(Object.create(BaseStorage), {
  key: 'MG_X_TOKEN',
})

// 国际化
export const LocaleStorage = Object.assign(Object.create(BaseStorage), {
  key: 'MG_LANG',
  get(key, defaultValue = 'zh-CN') {
    return getStorage(key || this.key, defaultValue)
  },
})

// 版本号
export const AppVersionStorage = Object.assign(Object.create(BaseStorage), {
  key: 'DH_APP_VERSION',
})
