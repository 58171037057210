import { TabsBarStorage, ChangePwdStorage, TokenStorage } from '@/utils/storage'
import * as UserApi from '@/api/user'

const state = () => ({
  accessToken: TokenStorage.get(),
  belongId: null,
  employeeName: null,
  employeeNo: null,
  employeeId: null,
  belongName: null,
  belongCode: null,
  permissions: [],
  createTime: null,
  defaultPurchaseWarehouseId: null,
  defaultPurchaseWarehouseCode: null,
  defaultPurchaseWarehouseName: null,
  defaultReturnWarehouseId: null,
  defaultReturnWarehouseCode: null,
  defaultReturnWarehouseName: null,
  belongType: null,
})
const getters = {
  accessToken: (state) => state.accessToken,
  belongId: (state) => state.belongId,
  permissions: (state) => state.permissions,
  employeeName: (state) => state.employeeName,
  employeeNo: (state) => state.employeeNo,
  employeeId: (state) => state.employeeId,
  belongName: (state) => state.belongName,
  belongCode: (state) => state.belongCode,
  createTime: (state) => state.createTime,
  defaultPurchaseWarehouseId: (state) => state.defaultPurchaseWarehouseId,
  defaultPurchaseWarehouseCode: (state) => state.defaultPurchaseWarehouseCode,
  defaultPurchaseWarehouseName: (state) => state.defaultPurchaseWarehouseName,
  defaultReturnWarehouseId: (state) => state.defaultReturnWarehouseId,
  defaultReturnWarehouseCode: (state) => state.defaultReturnWarehouseCode,
  defaultReturnWarehouseName: (state) => state.defaultReturnWarehouseName,
  belongType: (state) => state.belongType,
}
const mutations = {
  removeAccessToken(state) {
    state.accessToken = ''
    TokenStorage.clear()
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    TokenStorage.set(accessToken)
  },
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
  setBelongId(state, belongId) {
    state.belongId = belongId
  },
  setEmployeeNo(state, employeeNo) {
    state.employeeNo = employeeNo
  },
  setEmployeeName(state, employeeName) {
    state.employeeName = employeeName
  },
  setEmployeeId(state, employeeId) {
    state.employeeId = employeeId
  },
  setBelongName(state, belongName) {
    state.belongName = belongName
  },
  setBelongCode(state, belongCode) {
    state.belongCode = belongCode
  },
  setCreateTime(state, createTime) {
    state.createTime = createTime
  },
  setBelongType(state, belongType) {
    state.belongType = belongType
  },
  // 设置当前登录人默认仓库
  setDefaultWarehouse(state, item) {
    // 采购仓
    state.defaultPurchaseWarehouseId = item?.defaultPurchaseWarehouseId
    state.defaultPurchaseWarehouseCode = item?.defaultPurchaseWarehouseCode
    state.defaultPurchaseWarehouseName = item?.defaultPurchaseWarehouseName
    // 采购退货仓
    state.defaultReturnWarehouseId = item?.defaultReturnWarehouseId
    state.defaultReturnWarehouseCode = item?.defaultReturnWarehouseCode
    state.defaultReturnWarehouseName = item?.defaultReturnWarehouseName
  },
  clearUserData(state) {
    state.belongId = ''
    state.belongCode = ''
    state.employeeName = ''
    state.employeeNo = ''
    state.employeeId = ''
    state.permissions = []
    state.createTime = ''
  },
}
const actions = {
  removeAccessToken({ commit }) {
    commit('removeAccessToken')
    commit('clearUserData')
    TabsBarStorage.clear()
  },
  async login({ commit }, loginInfo) {
    const { data } = await UserApi.login({
      ...loginInfo,
      belongType: 'DRP_DH_LOGIN_USERS',
    })
    if (data.token) {
      commit('setAccessToken', data.token)
      ChangePwdStorage.set(data.needUpdatePwd)
    }
  },
  async getUserInfo({ commit, dispatch, state }) {
    const { data } = await UserApi.getUserInfo(state.accessToken)

    commit('setBelongId', data.belongId)

    // 过滤掉零售
    data.permissionsMenus[0].children = data.permissionsMenus[0].children.filter((item) => item.perms !== 'pos')
    commit('setPermissions', data.permissionsMenus)
    commit('setEmployeeName', data.employeeName)
    commit('setEmployeeNo', data.employeeNo)
    commit('setEmployeeId', data.employeeId)
    commit('setBelongName', data.belongName)
    commit('setBelongCode', data.belongCode)
    commit('setCreateTime', data.createTime)
    commit('setBelongType', data.belongType)
    dispatch('sys/getZipConfig', {}, { root: true })

    const { data: result = [] } = await UserApi.getEmployeeBusinessGroupInfo({ employeeNo: data.employeeNo })
    commit('setDefaultWarehouse', result[0])
  },
  // 修改密码
  async changePassword({ commit }, changePwdInfo) {
    await UserApi.putChangePassWord(changePwdInfo)
    commit('removeAccessToken')
    ChangePwdStorage.clear()
  },
  // 退出登录清空 accessToken 和路由
  async logout({ dispatch }, activeLogout) {
    activeLogout && (await UserApi.logout())
    dispatch('removeAccessToken')
    dispatch('routes/clear', {}, { root: true })
    dispatch('tabsBar/clear', {}, { root: true })
  },
}
export default { state, getters, mutations, actions }
