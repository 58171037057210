// 按钮权限
export default {
  inserted(el, { arg, value }, vnode) {
    // 如果 v-if 条件不成立，则不执行任何操作
    // if (!vnode.data.directives.some(dir => dir.name === 'if' && dir.value)) return
    const buttons = value?.meta?.buttons || el.__vue__?.$route?.meta?.buttons
    if (!buttons?.find((btn) => btn.path === arg)) {
      el?.parentNode?.removeChild(el)
      if (vnode?.data.ref) {
        delete vnode.context.$refs[vnode.data.ref]
      }
    }
  },
}
