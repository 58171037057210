function appendTo(el, binding, vnode) {
  const node = vnode.context.$refs[binding.arg]
  if (node) {
    node.$el.appendChild(el)
  }
}

export default {
  bind(el, binding, vnode) {
    appendTo(el, binding, vnode)
  },
  inserted(el, binding, vnode) {
    appendTo(el, binding, vnode)
  },
}
