
import CryptoJS from 'crypto-js';
// 加密
const AES = {
    encrypt (key, data) {
        // 如果传入的data是json对象，先转义为json字符串
        if (Object.prototype.toString.call(data) === "[object Object]") {
          try {
            data = JSON.stringify(data);
          } catch (error) {
            console.log('error:', error);
          }
        }
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const dataHex = CryptoJS.enc.Utf8.parse(data);
        const encrypted = CryptoJS.AES.encrypt(
            dataHex,
            keyHex,
            {
                mode:CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }
        );
        return encrypted.toString()
      },
      decrypt (key, encryptedVal) {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const decrypt = CryptoJS.AES.decrypt(
            encryptedVal,
            keyHex, 
            {
                mode:CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }
        );
        const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    }
}
export default AES;