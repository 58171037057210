import request from '@/utils/request'

export function getSystemBusinessSetList(data) {
  return request({
    url: '/mg/daohang-basic-api/sysBusinessConfig/page',
    method: 'post',
    data,
  })
}
export function saveSystemBusinessSet(data) {
  return request({
    url: '/mg/daohang-basic-api/sysBusinessConfig',
    method: 'put',
    loading: true,
    data,
  })
}
export function systemBusinessSetBatchDelete(data) {
  return request({
    url: '/mg/daohang-basic-api/sysBusinessConfig',
    method: 'delete',
    loading: true,
    data,
  })
}
export function getSysBusinessConfigById(id) {
  return request({
    url: `/mg/daohang-basic-api/sysBusinessConfig/${id}`,
    method: 'get',
  })
}

// 日结
export function checkoutRecord() {
  return request({
    url: `/mg/daohang-order-api/checkoutRecord`,
    method: 'post',
  })
}

// 获取最后日结时间
export function lastCheckoutTime() {
  return request({
    url: `/mg/daohang-order-api/checkoutRecord/lastCheckoutTime`,
    method: 'get',
  })
}

// 查询系统业务配置
export function querySysBusinessConfig(data) {
  return request({
    url: '/mg/daohang-basic-api/sysBusinessConfig/list',
    method: 'post',
    data,
    allowRepeat: true,
  })
}
export function sysBusinessConfigGetOne(data) {
  return request({
    url: '/mg/daohang-basic-api/sysBusinessConfig/getOne',
    method: 'post',
    data,
  })
}
