import request from '@/utils/request'
import i18n from '@/locale'
// const domain = process.env.NODE_ENV === 'development' ? '//10.18.75.23' : '/'
//商品加工单保存
export function stockCommodityProSkuProcessOrder(data) {
  return request({
    url: `/mg/daohang-stock-api/skuProcessOrder`,
    method: 'put',
    data,
    loading: true,
  })
}

//商品加工单分页查询
export function stockCommodityProPage(data) {
  return request({
    url: `/mg/daohang-stock-api/skuProcessOrder/page`,
    method: 'post',
    data,
  })
}
//商品加工单分页查询合计
export function stockCommodityProTotal(data) {
  return request({
    url: `/mg/daohang-stock-api/skuProcessOrder/total`,
    method: 'post',
    data,
  })
}
//商品加工单列表导出
export function stockCommodityProExcel(data) {
  return request({
    url: `/mg/daohang-stock-api/skuProcessOrder/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//商品加工单删除
export function stockCommodityProDelete(data) {
  return request({
    url: `/mg/daohang-stock-api/skuProcessOrder`,
    method: 'delete',
    loading: true,
    data,
  })
}
//商品加工单查询单条
export function stockCommodityProDetali(id) {
  return request({
    url: `/mg/daohang-stock-api/skuProcessOrder/${id}`,
    method: 'get',
    loading: true,
  })
}
//商品加工单状态变更
export function stockCommodityStatusChange(data) {
  return request({
    url: `/mg/daohang-stock-api/skuProcessOrder/statusChange`,
    method: 'post',
    loading: true,
    data,
  })
}
//加工前明细列表导出
export function stockCommodityItemExportExcel(data) {
  return request({
    url: `/mg/daohang-stock-api/skuProcessBeforeItem/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//加工后明细列表导出
export function stockCommodityResultItemExportExcel(data) {
  return request({
    url: `/mg/daohang-stock-api/skuProcessAfterItem/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
/**
 * 架装解析
 * @param {*} data
 * @returns
 */
export function framingParse(data) {
  return request({
    url: `/mg/daohang-stock-api/skuProcessOrder/framingParse`,
    method: 'post',
    data,
    loading: true,
    loadingText: i18n.t('架装解析中...'),
  })
}
